.WorkInProgress__Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #2C2A32; /* Darker background for a serious feel */
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding: 20px;
}

.WorkInProgress__Message {
    margin-bottom: 30px;
}

.WorkInProgress__Title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #99784D; /* Consistent bright blue for a pop of color */
    margin-bottom: 15px;
    opacity: 0.9;
    animation: fadeIn 1s ease-out;
}

.WorkInProgress__Description {
    font-size: 1.2rem;
    opacity: 0.8;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
    animation: fadeIn 1.2s ease-out;
}

@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(30px); }
    100% { opacity: 1; transform: translateY(0); }
}