.dashboard__mobile__signals__body__container {
    min-height: 100vh;
    width: calc(100vw - 30px);
    padding: 0px 15px 0px 15px;
}

.signals__body__container__background {
    width: 100%;
    height: 250px;

    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    border-radius: 10px;

    background-color: #31363F;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    margin-bottom: 15px;
}

.signals__body__item__title {
    position: absolute;

    font-size: 1.1rem;

    opacity: 0.25;
}

.signals__body__item__title.active {
    opacity: 1;
}