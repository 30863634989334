.SettingsPage__Container {
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    background-color: #2C2A32;
    padding: 20px;
}

.Dashboard__Mobile__Settings__Logout__Container {
    background-color: #584141;
    border: 2px solid #EB6161;

    margin-bottom: 20px;

    height: 60px;

    border-radius: 10px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;

    box-shadow: 0px 5px 5px 0px rgba(173, 77, 77, 0.25);
}