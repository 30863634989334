.date-picker-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-title {
    color: #333;
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.date-picker-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group-inline {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.date-input {
    padding: 8px;
    font-size: 16px;
}

.form-group label {
    color: #4b5563;
    font-size: 1rem;
    font-weight: bold;
}

.date-input {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.date-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.submit-button {
    background-color: #2d3237;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.submit-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
}

.progress-container {
    margin-top: 10px;
    text-align: center;
}

.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.progress-fill {
    height: 10px;
    background-color: #4caf50;
    width: 0%;
    transition: width 0.2s ease-in-out;
}

@media (max-width: 640px) {
    .date-picker-container {
        margin: 1rem;
        padding: 1rem;
    }
}