.homePage__footer {
    background-color: #F0F0F0;
    color: #A46648;
    padding: 0 7.8125vw;
    font-family: 'Roc Grotesk';
    font-weight: normal;
}

.homePage__footer__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 75px 0;
    max-width: 100%;
}

.homePage__footer__logo-section {
    flex: 0 0 200px;
}

.homePage__footer__logo-section img {
    max-width: 200px;
}

.homePage__footer__column {
    flex: 0 0 250px;
}

.homePage__footer__request-simulation-section {
    flex: 0 0 300px;
}

.homePage__footer__container h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.homePage__footer__follow-us-section h3,
.homePage__footer__ai-section h3,
.homePage__footer__sectoren-section h3 {
    margin-top: 30px;
}

.homePage__footer__container p,
.homePage__footer__container address,
.homePage__footer__container ul {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 100;
}

.homePage__footer__container ul {
    list-style: none;
    padding: 0;
}

.homePage__footer__container ul li {
    margin-bottom: 5px;
}

.homePage__footer__container a,
.homePage__footer-bottom a {
    text-decoration: none;
    color: #A46648;
}

.homePage__footer__container a:hover,
.homePage__footer-bottom a:hover {
    text-decoration: underline;
}

.homePage__footer__contact-section address {
    margin: 15px 0;
    font-style: normal;
}

.homePage__footer__contact-section p {
    max-width: 250px;
}

.homePage__footer__request-simulation-section button {
    padding: 10px;
    background-color: #A46648;
    color: white;
    border: none;
    cursor: pointer;
    max-width: 400px;
    font-weight: bold;
    font-size: 18px;
    box-sizing: border-box;
    width: 100%;
    margin: 20px 0;
}

.homePage__footer-bottom {
    padding: 20px 0;
    display: flex;
    justify-content: start;
    font-size: 14px;
    border-top: 1px solid rgba(164, 102, 72, 0.2);
}

.homePage__footer-bottom span {
    margin: 0 5px;
}

@media (max-width: 1350px) {
    .homePage__footer__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 60px 0;
    }

    .homePage__footer__logo-section {
        grid-column: span 2;
        text-align: center;
    }

    .homePage__footer__logo-section img {
        max-width: 150px;
        margin: 0 auto;
    }

    .homePage__footer__column,
    .homePage__footer__section {
        width: 100%;
        text-align: center;
    }

    .homePage__footer__request-simulation-section {
        grid-column: 1 / -1;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
    }

    .homePage__footer__contact-section address,
    .homePage__footer__contact-section p {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .homePage__footer {
        padding: 0 5vw;
    }
    
    .homePage__footer__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 40px 0 20px;
    }

    .homePage__footer__section {
        width: 100%;
        max-width: 100%;
    }

    .homePage__footer__logo-section img {
        max-width: 150px;
        margin: 0 auto 20px;
        display: block;
    }

    .homePage__footer__column {
        width: 100%;
        max-width: 100%;
    }

    .homePage__footer__request-simulation-section {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }

    .homePage__footer__request-simulation-section button {
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
        display: block;
        padding: 12px;
        font-size: 16px;
    }

    .homePage__footer__contact-section p,
    .homePage__footer__contact-section address {
        max-width: 100%;
        text-align: center;
    }

    .homePage__footer-bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px 0;
    }

    .homePage__footer-bottom a {
        display: block;
        margin: 5px 0;
    }

    .homePage__footer-bottom span {
        display: none;
    }
}