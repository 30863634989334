.Mobile__Currentflow {
    width: calc(100vw - 40px);
    aspect-ratio: 1;
    background-color: #31363F;

    font-weight: 400;

    margin-left: 20px;
    border-radius: 15px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}