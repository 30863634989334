.Mobile__Graph__Slider__Card__Container {
    min-width: 175px;
    height: 200px;
    background-color: #31363F;

    border-radius: 15px;
    
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    padding: 15px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    font-size: 1.4rem;

    margin-left: 20px;
}