.eboilerPage__container {
    padding: 3vh 8vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    color: #161615;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
}

.eboilerPage__container h1,
.eboilerPage__container h2 {
    color: #A46648;
    margin-bottom: 20px;
}

.eboilerPage__introduction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #EBE3DF;
    margin-bottom: 30px;
}

.eboilerPage__header {
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.eboilerPage__header h1 {
    margin: 0;
}

.eboilerPage__intro-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.eboilerPage__intro-text {
    flex: 1;
    text-align: left;
}

.eboilerPage__main {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 400px;
}

.eboilerPage__introduction h1 {
    font-size: 2.8em;
}

.eboilerPage__introduction p {
    font-size: 1.25em;
    margin-top: 20px;
}

.eboilerPage__main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}

.eboilerPage__main-text {
    flex: 3;
}

.eboilerPage__components {
    flex: 2;
    display: flex;
    justify-content: center;
}

.eboilerPage__components img {
    max-height: 500px;
    width: auto;
    object-fit: contain;
}

.eboilerPage__installation-and-hybrid {
    display: flex;
    flex-direction: column;
}

.eboilerPage__installation-content {
    width: 100%;
}

.eboilerPage__installed {
    margin-bottom: 30px;
}

.eboilerPage__installed-content {
    display: flex;
    align-items: center;
    gap: 30px;
}

.eboiler-serie-image {
    width: 50%;
    height: auto;
    object-fit: contain;
}

.eboilerPage__installed-content p {
    flex: 1;
}

/* Nieuwe container voor video en hybride naast elkaar */
.eboilerPage__video-hybrid-container {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

/* Stijl voor de video sectie */
.eboilerPage__video {
    flex: 1;
}

.eboilerPage__video video {
    width: 100%;
    height: auto;
    display: block;
}

/* Stijl voor de hybride sectie */
.eboilerPage__hybrid {
    flex: 1;
}

.eboilerPage__hybrid-content ul {
    margin-top: 15px;
    margin-bottom: 15px;
}

.eboilerPage__hybrid-content li {
    margin-top: 10px;
}

.eboilerPage__datasheet-link {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 4px;
    color: #A46648;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.2s ease, transform 0.2s ease;
    text-decoration: none;
}

.eboilerPage__datasheet-link:hover {
    background-color: #e9e9e9;
    transform: translateY(-2px);
}

.eboilerPage__datasheet-link svg {
    margin-right: 8px;
}

.eboiler__banner {
    width: 100%;
    max-width: 900px;
    display: block;
    margin: 0 auto 20px;
}

.eboiler__event {
    padding: 20px;
    margin-bottom: 20px;
}

.eboiler__section-title {
    color: white;
}

.eboiler__section-title {
    font-size: 24px;
    color: #A46648;
}

.eboiler__event {
    background-color: #f3f3f3;
    border-left: 5px solid #A46648;
    text-align: center;
}

.eboiler__button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #A46648;
    color: white;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
}

.eboiler__button:hover {
    background-color: #8a5337;
}

@media (max-width: 950px) {
    .eboilerPage__container {
        padding: 15px;
    }

    .eboilerPage__intro-content {
        flex-direction: column;
        align-items: center;
    }

    .eboilerPage__main {
        max-height: 300px;
    }

    .eboilerPage__main-content {
        flex-direction: column;
    }

    .eboilerPage__components {
        width: 100%;
        margin-top: 20px;
    }

    .eboilerPage__components img {
        max-height: 350px;
        max-width: 100%;
    }

    .eboilerPage__installed-content {
        flex-direction: column;
    }

    .eboiler-serie-image {
        width: 100%;
        max-width: 500px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .eboilerPage__introduction,
    .eboilerPage__header {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    
    .eboilerPage__video-hybrid-container {
        flex-direction: column;
    }
    
    .eboilerPage__datasheet-link {
        padding: 6px 10px;
        font-size: 12px;
    }

    .eboilerPage__introduction h1 {
        font-size: 2.2em;
    }
}

@media (max-width: 480px) {
    .eboilerPage__main {
        max-height: 250px;
    }
    
    .eboilerPage__components img {
        max-height: 280px;
    }
}