.homePage__sectoren__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.homePage__sectoren__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: start;
    align-items: stretch;
    background: #EBE3DF 0% 0% no-repeat padding-box;
    color: #A46648;
    box-sizing: border-box;
    padding: 5.208vw;
    margin: 0 7.8125vw -15vh 7.8125vw;
    gap: 5.208vw;
    z-index: 1;
}

.homePage__sectoren__container div {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.homePage__sectoren__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homePage__sectoren__container h3 {
    font-size: 24px;
    margin-bottom: 15px;
}

.homePage__sectoren__container p {
    font-size: 16px;
    margin: 0;
    flex-grow: 1;
}

.homePage__sectoren__icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.homePage__foto {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    min-height: 50vh;
    object-fit: cover;
}

.homePage__sectoren__discover {
    display: block;
    margin-top: 15px;
    font-size: 16px;
    color: #A46648;
    font-weight: bold;
    text-decoration: none;
}

.homePage__sectoren__discover:hover {
    opacity: 0.8;
}

@media (max-width: 1100px) {
    .homePage__sectoren__container {
        grid-template-columns: repeat(2, 1fr);
        gap: 10vh;
        padding: 75px;
    }
}

@media (max-width: 768px) {
    .homePage__sectoren__container {
        grid-template-columns: 1fr;
        gap: 8vh;
        padding: 40px;
    }
    
    .homePage__sectoren__header {
        flex-direction: row !important;
        gap: 10px;
    }
    
    .homePage__sectoren__icon {
        margin: 24px 10px 15px 0;
        font-size: 32px;
    }
}