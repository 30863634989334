.Mobile__Footer__Navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    overflow: visible;
}

.Mobile__Footer__Navbar__items__container {
    height: 60px;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: calc(25% - 30px);

    background-color: #31363F;
    z-index: 0;

    clip-path: inherit;
    position: relative;

    border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.Mobile__Footer__Navbar__items__active__container {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    background-color: #31363F;
    border-radius: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

.Mobile__Footer__Navbar__item {
    width: 30px;
    height: 30px;

    opacity: 1;
    transition: opacity 0.25s ease-in-out 0.25s
}

.Mobile__Footer__Navbar__item.active {
    opacity: 0;
    transition: opacity 0.25s ease-in-out 0.25s
}

.Mobile__Footer__Navbar__first__item {
    margin-left: calc(12.5% - 15px);
}

.Mobile__Footer__Navbar__items__active__icon {
    position: absolute;
    z-index: 3; /* Ensure it is above the red circle */
    width: 30px;
    height: 30px;
}

.Mobile__Footer__Navbar__items__container {
    position: relative;
    z-index: 0; /* Ensure it is below the active icon */
}

.Mobile__Footer__Navbar__items__active__container {
    position: absolute;
    z-index: 1;
}