.powerblockPage__container {
    padding: 3vh 8vw;
    font-family: 'Roc Grotesk';
    max-width: 1200px;
    margin: 0 auto;
    color: #161615;
    font-size: 1.1em;
}

.powerblockPage__container h1,
.powerblockPage__container h2 {
    color: #A46648;
    margin-bottom: 20px;
}

.powerblockPage__introduction {
    padding: 20px;
    background-color: #EBE3DF;
}

.powerblockPage__introduction h1 {
    font-size: 2.8em;
}

.powerblockPage__introduction p {
    font-size: 1.25em;
    margin-top: 20px;
}

.powerblockPage__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.powerblockPage__text-content {
    width: 60%;
}

.powerblockPage__text {
    margin-bottom: 20px;
}

.powerblockPage__features {
    margin-top: 20px;
}

.powerblockPage__features ul {
    list-style-type: disc;
    margin-left: 20px;
}

.powerblockPage__features li {
    margin-top: 10px;
}

.powerblockPage__image-block {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.powerblockPage__image-block img {
    max-width: 100%;
    height: auto;
}

.powerblockPage__open-image {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.powerblockPage__open-image img {
    width: 750px;
    height: auto;
}

.powerblockPage__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.powerblockPage__header h1 {
    margin: 0;
}

.powerblockPage__datasheet-link {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 4px;
    color: #A46648;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.2s ease, transform 0.2s ease;
    text-decoration: none;
}

.powerblockPage__datasheet-link:hover {
    background-color: #e9e9e9;
    transform: translateY(-2px);
}

.powerblockPage__datasheet-link svg {
    margin-right: 8px;
}

.datasheet-link {
    display: none;
}

@media (max-width: 768px) {
    .powerblockPage__container {
        padding: 10px;
    }

    .powerblockPage__content {
        flex-direction: column;
        align-items: center;
    }

    .powerblockPage__text-content,
    .powerblockPage__image-block {
        width: 100%;
    }

    .powerblockPage__image-block img {
        max-width: 60%;
        height: auto;
    }

    .powerblockPage__header {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    
    .powerblockPage__datasheet-link {
        padding: 6px 10px;
        font-size: 12px;
    }

    .powerblockPage__open-image img {
        width: 100%;
        height: auto;
    }
}