
.Dashboard__Mobile__InstalltionList__Container {
    padding: 0px 20px;
}

.Mobile__Installationlist__Header {
    background-color: #3b3a3f;

    border-radius: 10px 10px 0px 0px;
    height: 40px;
    align-items: center;

    display: flex;
    flex-flow: row nowrap;
}

.Mobile__Installationlist__userItem__container {
    background-color: #4b4a4e;

    padding: 5px 5px;

    box-sizing: border-box;
    border-bottom: #3b3a3f 1px solid;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.Mobile__Installationlist__userItem__button {
    background-color: #3f8664;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-left: auto;
}