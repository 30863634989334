.Mobile__Graph__Slider {
    min-width: 100vw;
    width: 100%;
    height: 250px;

    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    overflow-x: scroll;
}

.Mobile__Graph__Slider__Right__Side__Margin {
    height: 250px;
    min-width: 20px;
}