/* Header */
.offerte__app__header {
    background-color: #2D3237;
    padding: 20px;
    color: white;
    text-align: center;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.offerte__header {
    border: none;
    margin: calc(2rem - .14285714em) 0 1rem;
    padding: 0 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    color: rgba(0, 0, 0, .87);
}

.offerte__header:first-child {
    margin-top: -.14285714em
}

.offerte__header:last-child {
    margin-bottom: 0
}

h1.offerte__header {
    font-size: 2rem
}

h2.offerte__header {
    font-size: 1.71428571rem
}

h3.offerte__header {
    font-size: 1.28571429rem
}

h4.offerte__header {
    font-size: 1.07142857rem
}

h5.offerte__header {
    font-size: 1rem
}

.offerte__header--inverted {
    color: #fff
}

.offerte__header--red,
.offerte__p--red {
    color: #d9534f;
}

/* Footer */
.offerte__footer {
    background-color: #2D3237;
    padding: 10px;
    color: white;
    text-align: center;
    flex: 0 0 auto;
}

/* Buttons */
.offerte__tabs {
    margin: 15px 0;
    text-align: center;
}

.offerte__tab-button {
    margin: 5px 10px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.offerte__tab-button--active {
    background-color: #d3d3d3;
    color: black;
}

.offerte__tab-button--disabled {
    opacity: 0.5;
    pointer-events: none;
}

.offerte__reset-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #DB2828;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
}

.offerte__reset-button:hover {
    background-color: #D01919;
}

.offerte__button.offerte__button--positive,
.offerte__button.offerte__button--negative,
.offerte__button.offerte__button--blue {
    box-shadow: 0 0 0 0 rgba(34, 36, 38, .15) inset;
}

.offerte__button.offerte__button--positive {
    background-color: #21ba45;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.offerte__button.offerte__button--negative {
    background-color: #db2828;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.offerte__button.offerte__button--blue {
    background-color: #2185d0;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.offerte__button--lightblue {
    background-color: #2184d06c;
    color: #fff;
    text-shadow: none;
    background-image: none;
    cursor: default;
}

.offerte__button.offerte__button--fluid {
    display: block;
    width: 100%;
}

.offerte__icon-button.offerte__icon-button {
    padding: .78571429em .78571429em .78571429em;
}

.offerte__button {
    font-size: 1rem;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, .6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

.offerte__button,
.offerte__input {
    overflow: visible;
}

.offerte__button--left.offerte__button--labeled {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
}

.offerte__button--labeled {
    position: relative;
    padding-left: 4.07142857em !important;
    padding-right: 1.5em !important;
}

.offerte__button--massive {
    font-size: 1.71428571rem;
}

.offerte__button--small {
    font-size: .78571429rem;
}

.offerte__button--not-confirmed {
    background-color: rgba(255, 0, 0, 0.685);
    width: 100%;
    margin-top: 25px;
    height: 45px;
}

.offerte__button--confirmed {
    background-color: rgba(0, 128, 0, 0.616);
    cursor: default;
    width: 100%;
    margin-top: 25px;
    height: 45px;
}

.offerte__button--loading {
    background-color: gray;
    cursor: not-allowed;
    width: 100%;
    margin-top: 25px;
    height: 45px;
}

/* Forms */
.offerte__form {
    font-size: 1rem;
    position: relative;
    max-width: 100%;
}

.offerte__form-group {
    margin-bottom: 15px;
}

.offerte__select,
.offerte__input:not(.offerte__radio-input) {
    font-size: 1em;
    position: relative;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
}

.offerte__table__cell .offerte__input--offset {
    margin: 1em 0 -3px 0;
}

.offerte__table__cell .offerte__text--offset {
    margin: 1em 0 -3px 0;
}

.offerte__input::placeholder,
.offerte__select::placeholder {
    color: #d3d3d3;
    font-size: 0.9em;
}

.offerte__form-group--inline {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.offerte__form-group--inline .offerte__form-item {
    flex: 1;
}

.offerte__form-item--special {
    flex: 0 0 25%;
}

.offerte__form-radio {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.offerte__radio-input {
    margin-right: 10px;
    accent-color: black;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    outline: 0;
    font-size: 1em;
}

.offerte__form-radio .offerte__label {
    margin: 0;
    font-weight: 100;
    display: block;
    color: rgba(0, 0, 0, .87);
    font-size: 0.92857143em;
    text-transform: none;
}

/* TABLE */
.offerte__table {
    font-size: 1em;
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, .15);
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, .87);
    border-collapse: separate;
    border-spacing: 0;
}

.offerte__table .offerte__table__thead {
    box-shadow: none;
}

.offerte__table .offerte__table__thead .offerte__table__header {
    cursor: auto;
    background: #f9fafb;
    text-align: inherit;
    color: rgba(0, 0, 0, .87);
    padding: .92857143em .78571429em;
    font-weight: 700;
    text-transform: none;
    border-bottom: 1px solid rgba(34, 36, 38, .1);
    border-left: none;
}

.offerte__table .offerte__table__cell,
.offerte__table .offerte__table__header {
    transition: background .1s ease, color .1s ease;
}

.offerte__table--celled .offerte__table__row .offerte__table__cell:first-child,
.offerte__table--celled .offerte__table__row .offerte__table__header:first-child {
    border-left: none;
}

.offerte__table--celled .offerte__table__row .offerte__table__cell,
.offerte__table--celled .offerte__table__footer .offerte__table__header:last-child {
    border-left: 1px solid rgba(34, 36, 38, .1);
}

.offerte__table .offerte__table__row .offerte__table__cell {
    border-top: 1px solid rgba(34, 36, 38, .1);
}

.offerte__table .offerte__table__cell {
    padding: .78571429em .78571429em;
    text-align: inherit;
}

.offerte__table .offerte__table__cell-align-right {
    text-align: right;
}

.offerte__table--definition .offerte__table__cell:nth-child(2) {
    border-left: 1px solid rgba(34, 36, 38, .15);
}

.offerte__table .offerte__table__row:first-child .offerte__table__cell {
    border-top: none;
}

.offerte__table--definition .offerte__table__row .offerte__table__cell:first-child:not(.ignored) {
    background: rgba(0, 0, 0, .03);
    font-weight: 700;
    color: rgba(0, 0, 0, .95);
    text-transform: none;
    box-shadow: none;
    font-size: 1em;
}

.offerte__table .offerte__table__cell--one-wide,
.offerte__table .offerte__table__header--one-wide {
    width: 6.25%;
}

.offerte__table .offerte__table__cell--two-wide,
.offerte__table .offerte__table__header--two-wide {
    width: 12.5%;
}

.offerte__table .offerte__table__cell--three-wide,
.offerte__table .offerte__table__header--three-widee {
    width: 18.75%;
}

.offerte__table .offerte__table__cell--four-wide,
.offerte__table .offerte__table__header--four-wide {
    width: 25%;
}

.offerte__table .offerte__table__cell--eight-wide,
.offerte__table .offerte__table__header--eight-wide {
    width: 50%;
}

.offerte__table:last-child {
    margin-bottom: 0;
}

.offerte__brown-table {
    border-top: .2em solid #a5673f;
}

.offerte__table .offerte__table__footer {
    box-shadow: none;
}

.offerte__table .offerte__table__footer .offerte__table__header {
    cursor: auto;
    border-top: 1px solid rgba(34, 36, 38, .15);
    background: #f9fafb;
    text-align: inherit;
    color: rgba(0, 0, 0, .87);
    padding: .78571429em .78571429em;
    vertical-align: middle;
    font-style: normal;
    text-transform: none;
}

.offerte__table--green.offerte__table--inverted {
    background-color: #21ba45 !important;
    color: #fff !important;
}

.offerte__table--inverted {
    background: #333;
    color: rgba(255, 255, 255, .9);
    border: none;
}

.offerte__table .offerte__table__cell--negative,
.offerte__table .offerte__table__row--negative {
    background: #fff6f6 !important;
    color: #9f3a38 !important;
}

.offerte__table .offerte__table__cell--positive,
.offerte__table .offerte__table__row--positive {
    background: #fcfff5 !important;
    color: #2c662d !important;
}

/* ICON */
.offerte__icon {
    font-size: 1em;
    opacity: 1;
    margin: 0 .25rem 0 0;
    width: 1.18em;
    height: 1em;
    text-decoration: inherit;
    text-align: center;
    backface-visibility: hidden;
}

.offerte__icon:before {
    background: 0 0 !important;
}

.offerte__button--labeled>.offerte__icon {
    top: 0;
    left: 0;
}

.offerte__button.offerte__button--labeled>.offerte__icon {
    position: absolute;
    height: 100%;
    line-height: 1;
    border-radius: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    text-align: center;
    margin: 0;
    width: 2.57142857em;
    box-shadow: -1px 0 0 0 transparent inset;
}

.offerte__icon-button>.offerte__icon {
    opacity: .9;
    margin: 0 !important;
    vertical-align: top;
}

.offerte__button--labeled>.offerte__icon:after,
.offerte__button--labeled>.offerte__icon:before {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
}

/* IMAGE */
.offerte__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    background-color: transparent;
}

.offerte__image--small {
    width: 100%;
    height: 100%;
    max-height: 250px;
    font-size: .92857143rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offerte__image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.offerte__image--centered {
    margin-left: auto;
    margin-right: auto;
}

/* ANDERE */
.offerte__label,
.offerte__form-item span.offerte__label {
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
}

.offerte__warning {
    color: #d9534f;
    background-color: #f9f2f4;
    padding: 10px;
    border: 1px solid #d9534f;
    border-radius: 4px;
    margin: 10px 0;
    display: block;
}

.offerte__checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 10px;
    margin-top: 15px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.87);
    user-select: none;
}

.offerte__checkbox input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    outline: none;
    background-color: white;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin-right: 10px;
    cursor: pointer;
}

.offerte__checkbox input[type="checkbox"]:hover {
    border-color: #2D3237;
    box-shadow: 0 0 5px #2d323780;
}

.offerte__checkbox input[type="checkbox"]:checked {
    background-color: #2D3237;
    border-color: #2D3237;
    box-shadow: 0 0 5px #2d323780;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offerte__checkbox input[type="checkbox"]:checked::before {
    content: '✔';
    color: white;
    font-size: 16px;
    line-height: 1;
}

.offerte__checkbox label {
    margin: 0;
}

.offerte__toggle-inline-container {
    display: flex;
    align-items: center;
    gap: 15px; /* Ruimte tussen label en toggle */
    margin-bottom: 15px;
}

.offerte__toggle-wrapper {
    position: relative;
}

.offerte__toggle-input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.offerte__toggle-label {
    display: block;
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.offerte__toggle-switch {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.offerte__toggle-input:checked + .offerte__toggle-label {
    background-color: #4CAF50;
}

.offerte__toggle-input:checked + .offerte__toggle-label .offerte__toggle-switch {
    transform: translateX(25px);
}