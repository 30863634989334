.homePage__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4.2328vh 7.8125vw;
    font-size: 18px;
    background-color: #161615;
    font-family: 'Roc Grotesk';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
}

.navbar-visible {
    transform: translateY(0);
}

.navbar-hidden {
    transform: translateY(-100%);
}

body {
    margin: 0;
}

body.not-homepage {
    padding-top: 120px;
}

body.homepage {
    padding-top: 0;
}

.homePage__navbar--homepage {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.homePage__navbar__left,
.homePage__navbar__right {
    display: flex;
    align-items: center;
}

.homePage__navbar__left {
    flex: 1;
}

.homePage__navbar__left--homepage,
.homePage__navbar__right--homepage {
    margin-bottom: 5%;
}

.homePage__navbar__right {
    flex: 1;
    justify-content: flex-end;
}

.homePage__navbar__logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.homePage__navbar__logo img {
    height: 40px;
}

.homePage__navbar__logo--medium img {
    height: 50px;
}

.homePage__navbar__middle {
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.homePage__navbar__middle img {
    height: 215px;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.homePage__navbar__middle h2 {
    width: 100%;
    color: #A46648;
    text-align: center;
    margin: 0;
    font-size: 2.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
}

.homePage__navbar__menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.homePage__navbar__menu.left-menu {
    margin-left: 20px;
}

.homePage__navbar__menu.right-menu {
    margin-right: 20px;
}

.homePage__navbar__controller {
    position: relative;
}

.homePage__navbar__controller a {
    position: relative;
    color: #A46648;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
}

.homePage__navbar__controller a:hover {
    color: #A46648;
}

.homePage__navbar__controller b {
    position: relative;
}

.homePage__navbar__controller b::after {
    content: "AI";
    position: absolute;
    background-color: rgba(164, 102, 72, 0.32);
    color: #A46648;
    right: -18px;
    font-size: 9px;
    font-weight: bold;
    padding: 2px 3px 2px 2px;
    border-radius: 3px;
    line-height: 1;
}

.homePage__navbar__menu li {
    margin: 0 20px;
}

.homePage__navbar__menu li a {
    color: #A46648;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
}

.homePage__navbar__menu li a:hover {
    color: #A46648;
}

.homePage__dashboard__button {
    background-color: #A46648;
    color: #fff;
    padding: 15px 40px;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border: none;
    white-space: nowrap;
}

.homePage__dashboard__button:hover {
    background-color: #a46748b1;
}

.homePage__navbar__menu-items .homePage__dashboard__button {
    padding: 30px 100px;
}

.homePage__navbar__checkbox {
    display: none;
}

.homePage__navbar__hamburger {
    display: none;
    cursor: pointer;
}

.homePage__navbar__hamburger-lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
    cursor: pointer;
}

.homePage__navbar__hamburger-lines .line {
    width: 100%;
    height: 3px;
    background-color: #A46648;
    border-radius: 5px;
    transition: transform 0.4s ease-in-out;
}

.homePage__navbar__hamburger-lines.open .line1 {
    transform: rotate(45deg) translate(10px, 10px);
}

.homePage__navbar__hamburger-lines.open .line2 {
    opacity: 0;
}

.homePage__navbar__hamburger-lines.open .line3 {
    transform: rotate(-45deg) translate(5px, -5px);
}

.homePage__navbar__hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #161615;
    z-index: 15;
    font-size: 1.75rem;
    text-align: center;
    transition: transform 0.5s ease-in-out;
}

.homePage__navbar__logo,
.homePage__navbar__logo--medium,
.homePage__navbar__hamburger {
    z-index: 20;
}

.homePage__navbar__hamburger-menu.active {
    display: flex;
}

.homePage__navbar__menu-items {
    list-style: none;
    padding: 0;
    width: 90vw;
}

.homePage__navbar__menu-items li {
    margin: 1.5rem 0;
}

.homePage__navbar__menu-items li a {
    color: #fff;
    font-size: 1.75rem;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    display: block;
    width: 100%;
    padding: 10px 0;
}

.homePage__navbar__menu-items li a:hover {
    color: #A46648;
}

.homePage__navbar__menu-items li a.homePage__dashboard__button:hover {
    color: #fff;
}

.homePage__navbar__menu-items .homePage__dashboard__button {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    margin-top: 75px;
}

.homePage__navbar-bottom {
    position: absolute;
    bottom: 20px;
    align-items: center;
    font-size: 0.8rem;
    color: #CBCBCB;
    font-weight: normal;
    padding-top: 20px;
    border-top: 1px solid rgba(203, 203, 203, 0.09);
}

.homePage__navbar-bottom a {
    color: #CBCBCB;
    text-decoration: none;
    margin: 5px 0;
}

.homePage__navbar-bottom a:hover {
    text-decoration: underline;
}

.homePage__navbar__language-dropdown {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: #A46648;
    font-size: 14px;
    user-select: none;
}

.homePage__navbar__language-dropdown .language-selector {
    display: flex;
    align-items: center;
}

.homePage__navbar__language-dropdown .arrow {
    margin-left: 5px;
    font-size: 10px;
}

.homePage__navbar__language-dropdown .dropdown-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #161615;
    border: 1px solid #A46648;
    border-radius: 4px;
    list-style: none;
    padding: 5px 0;
    margin: 0;
    z-index: 100;
    min-width: 55px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.homePage__navbar__language-dropdown .dropdown-menu li {
    padding: 5px 10px;
    color: #CBCBCB;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.homePage__navbar__language-dropdown .dropdown-menu li:hover {
    background-color: #A46648;
    color: #fff;
}

.homePage__navbar--homepage.navbar-visible {
    background-color: rgba(22, 22, 21, 0.9);
}

.homePage__navbar--homepage.navbar-transparent {
    background-color: transparent !important;
}

.homePage__navbar__hamburger-menu.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

@media (max-width: 1150px) {
    .homePage__navbar {
        padding: 35px 75px 25px 10vw;
    }

    .homePage__navbar__left {
        margin-bottom: 0;
    }

    .homePage__navbar__hamburger {
        display: block;
    }

    .homePage__navbar__dashboard,
    .homePage__navbar__menu {
        display: none;
    }

    .homePage__navbar__middle img {
        height: 150px;
    }
}
