.Mobile__Dashboard__Signal__historic__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;

    justify-content: space-between;
    
    
    margin-bottom: 2.5%;
}

.Mobile__Dashboard__Signal__historic__graph__legend__container {
    width: calc(100% - 10px);
    height: 40px;

    margin-top: 40px;
    margin-left: 10px;

    /* background-color: red; */

    display: flex;
    flex-flow: row nowrap;

}

.Mobile__Dashboard__Signal__historic__graph__container {
    width: 100%;
    height: 65%;
}

.historic__graph__legend__item__icon {
    width: 15px;
    height: 15px;

    border-radius: 5px;
}

.historic__graph__legend__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
    margin-right: 15px;
}

.signal__historic__graph__tooltip__item__container {
    background-color: #31363F;

    border-radius: 5px;

    padding: 4px 5px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
    
    font-weight: normal;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    font-size: 0.8rem;
}

.signal__historic__graph__tooltip__item__icon {
    width: 2.5px;
    height: 15px;

    border-radius: 5px;
}

.custom-tooltip {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5px;
}