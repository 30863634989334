.Dashboard__Mobile__Overview__Navbar {
    padding: 20px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.Mobile__Dashboard__Slider__Container {
    height: 290px;
    min-width: 100vw;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.Mobile__Footer__Navbar__Item {
    width: 40px;
    height: 40px;
    background-color: black;
}

.Mobile__Dashboard__Body__Container {
    padding-bottom: 80px;
}