.homePage__header {
    background-image: url('../../../Images/background.png'),
        linear-gradient(180deg, #161615bf 30%, #00000080 55%, #00000000 100%);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: #fff;
    opacity: 1;
    position: relative;
    overflow: hidden;
    font-family: 'Roc Grotesk';
}

.homePage__news-alert {
    position: absolute;
    top: 175px;
    right: 150px;
    z-index: 999;
}

.homePage__navbar__text__segment {
    margin-top: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homePage__navbar__text__segment .homePage__navbar__subtitle {
    font-size: 16px;
    letter-spacing: 3px;
    display: none;
}

.homePage__navbar__text__segment .homePage__navbar__title {
    font-size: 52px;
    margin: 35px 0 0 0;
}

.homePage__navbar__text__segment .homePage__navbar__description {
    font-size: 18px;
    width: 500px;
    margin: 50px 0;
}

.homePage__navbar__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.04vw;
    position: relative;
    padding: 10.58vh 15.63vw 29vh 15.63vw;
}

.homePage__navbar__body .homePage__nabvar__card {
    width: 20.83vw;
    height: 37.037vh;
    margin-bottom: 15vh;
    background: rgba(22, 22, 21, 0.65);
    padding: 2.6vw 2.6vw 0 2.6vw;
    color: #A46648;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homePage__navbar__body .homePage__nabvar__card h3 {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 1.04vw;
}

.homePage__navbar__body .homePage__nabvar__card p {
    color: #CBCBCB;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    flex-grow: 1;
}

.homePage__navbar__body .homePage__nabvar__card .homePage__nabvar__divider {
    border-top: 1px solid rgba(203, 203, 203, 0.09);
    width: 26.04vw;
    margin: auto -2.6vw 0 -2.6vw;
}

.homePage__navbar__body .homePage__nabvar__card a {
    display: block;
    color: rgba(203, 203, 203, 0.44);
    font-size: 12px;
    margin: 1.04vw 0;
    font-weight: normal;
}

.homePage__navbar__body .homePage__nabvar__card a:hover {
    color: #A46648;
}

.homePage__nabvar__card a {
    color: #A46648;
    text-decoration: none;
    font-weight: bold;
}

.homePage__navbar__body .homePage__nabvar__card:nth-child(odd) {
    grid-column: 1;
    margin-left: 0;
    margin-right: 7.35vw;
}

.homePage__navbar__body .homePage__nabvar__card:nth-child(even) {
    grid-column: 2;
    margin-right: 0;
    margin-left: 7.35vw;
    transform: translateY(30vh);
}

.center-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background: #A46648;
}

.scroll-hexagon {
    width: 15px;
    height: 15px;
    background-color: #A46648;
    clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
    position: absolute;
}

.homePage__navbar__dayAhead__segment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(22, 22, 21, 0.65);
    padding: 10.582vh 7.8125vw;
    color: #fff;
    gap: 50px;
}

.homePage__navbar__text-section {
    min-width: 300px;
    width: 30%;
}

.homePage__navbar__subtitle {
    color: #A46648;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 14px;
    margin: 0;
}

.homePage__navbar__title {
    font-size: 42px;
    font-weight: bold;
    line-height: 1.2;
    margin: 20px 0;
}

.homePage__navbar__text-section p {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 1.5;
}

.homePage__navbar__links {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    gap: 0.5rem;
    text-decoration: none;
}

.homePage__navbar__links a {
    color: #A46648;
    text-decoration: none;
    font-weight: bold;
}

.homePage__navbar__links a:last-child {
    color: #fff;
}

.homePage__navbar__links a:hover {
    text-decoration: underline;
}

.homePage__navbar__graph-section {
    max-width: 70%;
    height: 100%;
    flex-grow: 1;
}

.homePage__navbar__graph {
    width: 100%;
    height: 37vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1515px) {
    .homePage__navbar__links {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 1450px) {
    .homePage__navbar__text__segment {
        margin-top: 350px;
    }

    .homePage__navbar__text__segment .homePage__navbar__subtitle {
        display: block;
    }

    .homePage__navbar__middle {
        display: none;
    }

    .homePage__news-alert {
        position: absolute;
        top: 115px;
        right: 150px;
    }
}

@media (max-width: 1150px) {
    .homePage__navbar__text__segment {
        margin-top: 250px;
    }
}

@media (max-width: 950px) {
    .homePage__navbar__text__segment {
        padding: 0 10vw;
    }

    .homePage__navbar__text__segment .homePage__navbar__description {
        width: 80vw;
    }

    .homePage__navbar__body {
        grid-template-columns: 1fr;
        padding: 10.58vh 8vw 15vh 8vw;
        gap: 75px;
    }

    .homePage__navbar__body .homePage__nabvar__card:nth-child(odd),
    .homePage__navbar__body .homePage__nabvar__card:nth-child(even) {
        grid-column: 1;
        z-index: 3;
        transform: none;
        margin: 0;
    }

    .homePage__navbar__body .homePage__nabvar__card {
        width: auto;
    }

    .homePage__navbar__dayAhead__segment {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 10.582vh 10px;
    }

    .homePage__navbar__text-section {
        width: 100%;
        text-align: center;
    }

    .homePage__navbar__graph-section {
        max-width: 100%;
        width: 100%;
        margin-top: 20px;
    }

    .homePage__navbar__text__segment .homePage__navbar__title {
        font-size: 35px;
    }

    .homePage__navbar__dayAhead__segment .homePage__navbar__title {
        font-size: 28px;
    }

    /* .homePage__navbar__dayAhead__segment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(22, 22, 21, 0.65);
        padding: 10.582vh 7.8125vw;
        color: #fff;
        gap: 50px;
    } */

    .homePage__navbar__text-section p {
        font-size: 14px;
    }

    .homePage__navbar__links a {
        font-size: 12px;
    }

    .homePage__navbar__links {
        flex-direction: row;
        justify-content: center;
        gap: 30px;
    }

    .homePage__nabvar__divider {
        width: 100%;
    }
}